import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../../Wow";
import Form from "../../Form/Contact";

const OrderInfo = () => {
  const { t } = useTranslation();
  return (
    <>
    <ComponentWow>
      <div className="container pt-5">
        <div className="text-center mb-4 d-flex flex-column">
          <h5 className="section-title px-5 mb-5">
            <span className="px-2">{t("order.orderInfo")}</span>
          </h5>

          <table className="table table-responsive table-borderless">
            <tbody>
              <tr>
                <th scope="row">{t("orderInfo.portOfLoading")}</th>
                <td>: Tanjun Priok, Jakarta, Indonesia</td>
              </tr>
              <tr>
                <th scope="row">{t("orderInfo.minOrder")}</th>
                <td>: 18 MT / 20″FCL</td>
              </tr>
              <tr>
                <th scope="row">{t("orderInfo.productionCapability")}</th>
                <td>: 6 MT/Day</td>
              </tr>
              <tr>
                <th scope="row">{t("orderInfo.packaging")}</th>
                <td>: 1 kg carton box; 10 kg master box</td>
              </tr>
              <tr>
                <th scope="row">{t("orderInfo.paymentTerms")}</th>
                <td>: 50% DP, rest against B/L</td>
              </tr>
              <tr>
                <th scope="row">{t("orderInfo.paymentMethod")}</th>
                <td>: 50% Bank transfer (TT)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ComponentWow>
    <ComponentWow>
    <div className="container mt-5 mb-2">
      <div className="row">
        <div className="col-md-2" />
        <div className="col-md-8">
          <h3 className="mb-4 text-center">{t("order.form.title")}</h3>
          <div className="col-sm-12 col-md-12">
            <Form />
          </div>
        </div>
        <div className="col-md-2" />
      </div>
    </div>
  </ComponentWow>
  </>
  );
};

export default OrderInfo;
